'use es6';

export const ID = ['id'];
export const TYPE = ['type'];
export const CAPTURE_VISITOR_EMAIL_ADDRESS = ['captureVisitorEmailAddress'];
export const CUSTOM_EMAIL_CAPTURE_DELAY = ['customEmailCaptureDelay'];
export const INBOX_ID = ['inboxId'];
export const ROUTING_RULES = ['routingRules'];
export const SEND_FROM_ROUTING_RULES = ['sendFromRoutingRules'];
export const GDPR_EXPLICIT_CONSENT_REQUIRED = ['gdprExplicitConsentRequired'];
export const GDPR_CONSENT_TO_PROCESS_ENABLED = ['gdprConsentToProcessEnabled'];
export const GDPR_COOKIE_CONSENT_PROMPT = ['cookieConsentPrompt'];
export const GDPR_CONSENT_TO_COMMUNICATE_ENABLED = ['gdprConsentToCommunicateEnabled'];
export const CUSTOM_CONSENT_TO_PROCESS_MESSAGE = ['customConsentToProcessMessage'];
export const CUSTOM_CONSENT_TO_COMMUNICATE_MESSAGE = ['customConsentToCommunicateMessage'];
export const CONSENT_TO_COMMUNICATE_SUBSCRIPTION_ID = ['consentToCommunicateSubscriptionId'];
export const CHAT_HEADING_CONFIG = ['chatHeadingConfig'];
export const POP_OPEN_WELCOME_MESSAGE = ['popOpenWelcomeMessage'];
export const POP_OPEN_WIDGET = ['popOpenWidget'];
export const POP_MESSAGE_ON_SMALL_SCREENS = ['popMessageOnSmallScreens'];
export const LANGUAGE = ['language'];
export const CLIENT_TRIGGERS = ['clientTriggers'];
export const DISPLAY_BOT_QUICK_REPLIES_OUTSIDE_CHAT = ['displayBotQuickReplies'];