/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import { initializePubSub } from 'conversations-internal-pub-sub/redux/actions/initializePubSub';
import Raven from 'raven-js';
import { getToken } from '../clients/getToken';
import { resolveBuilder } from '../resolvers/resolveBuilder';
import { trackUserInteraction } from '../../actions/trackUserInteraction';
import { getSessionId } from '../../selectors/widgetDataSelectors/getSessionId';
import { networkOnline } from '../../actions/PubSubStatusActions/networkOnline';
import { networkOffline } from '../../actions/PubSubStatusActions/networkOffline';
import { fetchCurrentThreadHistory } from '../../thread-histories/actions/fetchCurrentThreadHistory';
import { fetchVisitorThreads } from '../../threads/actions/ThreadActions';
import { getThreads } from '../../threads/selectors/getThreads';
import { diffPrimitives } from 'conversations-internal-pub-sub/utils/diffPrimitives';
import { getChannelName } from '../../threads/operators/threadGetters';
import { onThreadCreatedAndNetworkOnline } from '../../actions/PubSubStatusActions/onThreadCreatedAndNetworkOnline';
import { getHubspotUtk } from '../../query-params/hubspotUtk';
import { getWidgetDataGates } from '../../widget-data/selectors/widgetDataSelectors';
export const startPubSub = ({
  newThreadCreated
} = {}) => {
  return (dispatch, getState) => {
    // TODO [Follow up]: Find a better home for this
    dispatch(trackUserInteraction());
    const clientOptions = {
      skipSubscribeReauth: false,
      authCallback: (__tokenParams, callback) => {
        const sessionId = getSessionId(getState());
        const hubspotUtk = getHubspotUtk();
        const gates = getWidgetDataGates(getState());
        const isUngatedForNewClientIdFormat = gates.get('Conversations:LiveChat:VersionedAblyClientId') || false;
        getToken({
          sessionId,
          hubspotUtk,
          isUngatedForNewClientIdFormat
        }).then(tokenRequest => {
          try {
            const channels = getThreads(getState()).map(getChannelName).valueSeq().toJS();
            const capability = JSON.parse(tokenRequest.capability);
            const grantedChannels = Object.keys(capability);
            const difference = diffPrimitives(channels, grantedChannels);

            if (difference.length) {
              Raven.captureMessage('capability mismatch', {
                level: 'error',
                extra: {
                  channels,
                  grantedChannels,
                  difference,
                  sessionId
                }
              });
            }
          } catch (error) {// Do nothing
          }

          callback(null, tokenRequest);
        }, error => callback(error));
      }
    };
    const lifecycleHooks = {
      onConnect({
        connectionWasSuspended,
        reconnected
      }) {
        dispatch(networkOnline());

        if (newThreadCreated) {
          dispatch(onThreadCreatedAndNetworkOnline());
        }

        if (connectionWasSuspended || reconnected) {
          dispatch(fetchCurrentThreadHistory());
        }

        if (connectionWasSuspended) {
          dispatch(fetchVisitorThreads());
        }
      },

      onDisconnect() {
        dispatch(networkOffline());
      },

      onFailure() {
        dispatch(networkOffline());
      }

    };
    dispatch(initializePubSub({
      clientOptions,
      lifecycleHooks,
      resolveBuilder
    }));
  };
};